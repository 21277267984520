import React from 'react'
import { useTranslation } from 'react-i18next'
import DocumentationLayout from '../layouts/DocumentationLayout'
import Seo from '../components/Seo'
import CommunityIcon from '../assets/svgs/people.svg'
import HelpIcon from '../assets/svgs/help-circle_36.svg'
import PortalIcon from '../assets/svgs/application_36.svg'
import Link from '../components/Link'
import { PAGE_VIEWED } from '../constants/metrics'
import MetricsService from '../services/metricsService'

const Support = (): JSX.Element => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const pageName = 'Support'

  metricsService.track(PAGE_VIEWED, {
    pageName: pageName,
  })

  const supportItems = [
    {
      icon: <CommunityIcon />,
      title: 'support.community',
      description: 'support.communityDescription',
      buttonText: 'support.communityBtnText',
      buttonPath:
        'https://community.cisco.com/t5/webex-for-developers/bd-p/disc-webex-developers',
    },
    {
      icon: <HelpIcon />,
      title: 'support.request',
      description: 'support.requestDescription',
      buttonText: 'support.request',
      buttonPath: 'https://devsupport.webex.com/hc/en-us/requests/new',
      linkText: 'support.requestEmail',
      linkPath: 'mailto:devsupport@webex.com',
    },
    {
      icon: <PortalIcon />,
      title: 'support.portal',
      description: 'support.portalDescription',
      buttonText: 'support.viewRequests',
      buttonPath: 'https://devsupport.webex.com/requests',
    },
  ]

  return (
    <DocumentationLayout>
      <Seo title={pageName} />
      <div id="support-container" className="columns large-8 guide-container">
        <div className="content">
          <h1>{t('support.supportHeader')}</h1>
          <p className="support-overview">{t('support.supportOverview')}</p>
          <div className="support-item-container">
            {supportItems.map((item, index) => {
              return (
                <div key={index} className="support-item">
                  <div className="column">
                    {item.icon}
                    <span>{t(item.title)}</span>
                  </div>
                  <div className="column">
                    <p>{t(item.description)}</p>
                    <Link
                      className="md-button md-button--36 md-button--blue"
                      to={item.buttonPath}
                    >
                      {t(item.buttonText)}
                    </Link>
                    {item.linkText && (
                      <Link className="support-link" to={item.linkPath}>
                        {t(item.linkText)}
                      </Link>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="support-footer">
            <p>
              {t('support.checkStatus')}
              <Link to={'https://status.webex.com'}>
                https://status.webex.com
              </Link>
            </p>
            <span>
              <b>{t('support.footerMoreText')}</b>
              {t('support.footerFinalText')}
              <Link to={'https://devsupport.webex.com/hc/en-us/requests/new'}>
                {t('support.footerSubmitRequest')}
              </Link>
              !
            </span>
          </div>
        </div>
      </div>
    </DocumentationLayout>
  )
}

export default Support
